.datePicker {
  position: relative;

  & input:focus-visible {
    outline: none;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    pointer-events: none;
  }
  p {
    color: var(--color-white);
    margin-bottom: 5px;
  }
  .inputDate {
    border: 1px solid #a9a9ba;
    padding: 16px 24px;
    border-radius: 12px;
    width: 100%;
  }
}

.requiredInput {
  color: var(--color-error-5);
  margin-left: 2px;
  font-size: 20px;
  position: absolute;
}
