.filterDateTran {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > div:first-child {
    margin-right: 30px;
  }
  @media (max-width: 500px) {
    & > div:first-child {
      margin-right: 0;
    }
    flex-wrap: wrap;
    gap: 10px 0;
  }
}

.buttonFilterDate {
  text-align: right;
  display: contents;

  & button {
    width: 40%;
    padding: 10px;
    margin-left: 10px;
    margin-top: 20px;
    @media (max-width: 500px) {
      margin-left: 0;
    }
    // text-align: center;
  }
}
