.changeInput {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.inputMeet {
  width: 400px;
  word-break: break-all;
}

.inputDate {
  width: 700px;
}

.infoMeet {
  font-size: 16px;
  margin: 15px 0;
  cursor: pointer;
  word-break: break-all;
  & strong:hover {
    text-decoration: underline;
  }
}
