.submit {
  padding: 20px 100px;
  color: var(--color-gray-7);
  background: var(--color-yellow);
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  @media (max-width: 590px) {
    padding: 5px 40px;
    & div {
      font-size: 14px;
    }
  }
}

.cancel {
  padding: 12px 100px;
  color: var(--color-gray-7);
  border: 1px solid var(--color-yellow);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  background: var(--color-white);
  @media (max-width: 590px) {
    padding: 5px 20px;
    & div {
      font-size: 12px;
    }
  }
}

.loadingSpan {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #ffffff8f;
}
