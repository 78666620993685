.paginationWrapper {
  // padding: 2rem 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator {
  width: 1rem;
  margin: 0 0.25rem;
  color: var(--color-primary-5);
}
.pageItem {
  background: transparent;
  cursor: pointer;
  border: none;
  height: 2rem;
  width: auto;
  margin: 0 0.25rem;
  padding: 5px 10px;
  font-weight: 600;
  color: var(--color-primary-5);
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
}
.active {
  background-color: var(--color-primary-5);
  color: var(--color-white);
  border-radius: 10px;
}
.sides {
  font-size: 20px;
  margin-top: -5px;
}
