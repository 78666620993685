@import './reset';
@import './font';
@import './font-asap';
@import './color.css';

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Public Sans';
  font-size: 14px;
  line-height: normal;
  text-rendering: optimizeSpeed;
}
html,
body,
div {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #c0c3cb 14%, #d8dbe5 64%);
    border-radius: 10px;
  }
}
.container {
  // max-width: 1440px;
  margin: 0 auto;
  .container-wrapper {
    padding: 0 160px;
    @media (max-width: 1400px) {
      padding: 0 20px;
    }
    @media (max-width: 650px) {
      padding: 0 10px;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.head_table {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.posUser {
  color: var(--color-gray-6);
  font-style: italic;
  font-size: 16px;
}

.text-err {
  color: var(--color-error-5);
  position: absolute;
  margin-top: 5px;
  font-size: 12px;
}

.btn-submit {
  background: var(--color-linear);
  padding: 12px 24px;
  color: var(--color-white);
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}
input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -2px;
  left: -2px;
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-3);
}
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  top: -1px;
  left: -1px;
  background-color: var(--color-primary-5);
  border: 1px solid var(--color-primary-5);
}
input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;

  border: solid 1px var(--color-white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(158, 152, 156);
}
.gm-style img {
  object-fit: contain;
}

.react-datepicker__navigation--next {
  /* right: 2px; */
  left: 60% !important;
}

.react-datepicker__current-month {
  width: 70% !important;
  margin-left: 10px;
  margin-top: 2px !important;
}
.react-datepicker__header__dropdown {
  display: inline-block !important;
  position: absolute !important;
  right: 20px;
  top: 10px;
}
.react-datepicker__year-dropdown {
  width: 80px !important;
  font-size: 13px;
  left: -99%;
  top: 0px;
}
.react-datepicker__year-option--selected {
  margin-left: -10px;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  left: -20px !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  left: -20px !important;
}

.slick-slide {
  // width: 300px !important;
  margin-right: 20px;
}
.slick-slide div {
  vertical-align: middle;
}

.slick-list .slick-track {
  display: flex;
}

.slick-arrow {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

select {
  border: 1px solid var(--color-gray-5);
}

select:invalid,
select option[value=''],
select option:not([value]) {
  color: var(--color-gray-5);
}

select option[value] {
  color: var(--color-gray-7);
}

.input {
  padding: 10px;
  border-radius: 15px;
  background: #f2f2f2;
  border: 1px solid #ffffff;
  text-align: center;
  width: 70%;
}

.input_hidden {
  display: none;
}

.grp_btn {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  width: 100%;
  margin-top: 10px;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 52px;
  cursor: pointer;
}

.btn_confirm {
  background: linear-gradient(
    180deg,
    #47c3ed 0%,
    #ffffff 0.01%,
    #79d3f2 4.69%,
    #47c3ed 17.19%,
    #1291d2 100%
  );
  box-shadow: 0px 0px 5px #47c3ed;
  border: none;
  color: #fff;
  margin-left: 10px;
  width: calc(100% - 70% - 10px);
}
