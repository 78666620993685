.Search {
  position: relative;
  .InputSearch {
    border: 1px solid var(--color-gray-5);
    padding: 16px 24px;
    border-radius: 12px;
    width: 100%;
    &[type="text"] {
      padding-left: 40px;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .IconSearch {
    position: absolute;
    top: 15px;
    left: 12px;
  }
}
