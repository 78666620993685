.wrapperBox {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 16px;
  padding: 24px;
  max-width: 300px;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  .nameBox {
    font-weight: 600;
    line-height: 1.57143;
    font-size: 16px;
    color: var(--color-gray-7);
  }
  .totalBox {
    font-size: 28px;
    font-weight: 600;
    color: var(--color-primary-5);
  }

  @media (max-width: 470px) {
    max-width: 100%;
    .totalBox {
      font-size: 22px;
      margin-top: 10px;
    }
    .nameBox {
      font-size: 14px;
    }
  }
}
