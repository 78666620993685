.tableContainer {
  border-radius: 12px;
  background: var(--color-primary-1);
  padding: 0 15px;
  margin: 30px 0;
  width: 100%;
  overflow: auto;
  position: relative;
}

.transTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
  box-sizing: border-box;
  min-height: 100px;
  @media (max-width: 800px) {
    width: 800px;
  }
  th {
    font-weight: 600;
    color: #565656;
    text-align: start;
    padding: 16px;
    box-sizing: border-box;
    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
    }
  }
  td {
    word-wrap: break-word;
    box-sizing: border-box;
    padding: 20px;
    min-width: 150px;
    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
    }
  }

  .tableRow {
    background: white;
    color: var(--color-gray-6);
  }
  .sttTable {
    min-width: 70px !important;
  }
  .checkboxTable {
    min-width: 30px !important;
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.2);
  transition: top 0.4s, opacity 0.4s;
  animation-duration: 1s;
  width: 100%;
  height: 100%;
  z-index: 2000;
  & div {
    position: absolute;
    left: 45%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}

.notData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.iconDetail {
  & svg {
    max-width: 20px;
    height: 20px;
    object-fit: cover;
    cursor: pointer;
  }
}
