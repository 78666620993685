$fontBold: 600;
$fontRegular: 400;

.font-bold-36 {
  font-weight: $fontBold;
  font-size: 36px;
  line-height: 54px;
}

.font-bold-28 {
  font-weight: $fontBold;
  font-size: 28px;
  line-height: 42px;
}

.font-bold-24 {
  font-weight: $fontBold;
  font-size: 24px;
  line-height: 36px;
}

.font-bold-20 {
  font-weight: $fontBold;
  font-size: 20px;
  line-height: 30px;
}

.font-bold-18 {
  font-weight: $fontBold;
  font-size: 18px;
  line-height: 28px;
}

.font-bold-16 {
  font-weight: $fontBold;
  font-size: 16px;
  line-height: 24px;
}

.font-regular-14 {
  font-weight: $fontBold;
  font-size: 14px;
  line-height: 22px;
}

.font-regular-12 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.font-regular-10 {
  font-weight: $fontBold;
  font-size: 10px;
  line-height: 16px;
}

.font-regular-36 {
  font-weight: $fontRegular;
  font-size: 36px;
  line-height: 54px;
}

.font-regular-28 {
  font-weight: $fontRegular;
  font-size: 28px;
  line-height: 42px;
}

.font-regular-24 {
  font-weight: $fontRegular;
  font-size: 24px;
  line-height: 36px;
}

.font-regular-20 {
  font-weight: $fontRegular;
  font-size: 20px;
  line-height: 30px;
}

.font-regular-18 {
  font-weight: $fontRegular;
  font-size: 18px;
  line-height: 28px;
}

.font-regular-16 {
  font-weight: $fontRegular;
  font-size: 16px;
  line-height: 24px;
}

.font-regular-14 {
  font-weight: $fontRegular;
  font-size: 14px;
  line-height: 22px;
}

.font-regular-12 {
  font-weight: $fontRegular;
  font-size: 12px;
  line-height: 18px;
}

.font-regular-10 {
  font-weight: $fontRegular;
  font-size: 10px;
  line-height: 16px;
}

.font-medium-16 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.font-medium-14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.font-medium-12 {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.font-italic-12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
