.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  transition: top 0.4s, opacity 0.4s;
  animation-duration: 1s;
  animation-name: animatetop;
  color: var(--color-gray-7);
  font-size: 16px;
  .modalContent {
    transition: opacity 0.3s;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
    box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.06);
    z-index: 10;
    padding: 60px;
    border-radius: 10px;
    width: 540px;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    text-align: center;
    @media (max-width: 600px) {
      max-width: calc(100% - 20px);
      padding: 30px 20px;
      min-width: calc(100% - 20px);
    }
  }
}

.closeOTP {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}

.descMD5 {
  line-height: 28px;
  a {
    color: var(--color-primary-5) !important;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
    font-size: 14px;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
