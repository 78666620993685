body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  background-image: url('../public/background.png');
  background-repeat: repeat-y;
  background-size: cover;
  color: white;
  padding: 20px;
  position: relative;
  min-height: 100vh;
}
.form-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.headApp {
  display: flex;
}
.logo {
  width: 72px;
  height: 72px;
  border-radius: 16px;
  position: absolute;
}
.title {
  font-size: 30px;
  line-height: 29.26px;
  font-weight: 600;
  margin: 32px 0 0 80px;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 20px;
  }
}

.wrapperTab {
  display: inline-flex;
  font-size: 20px;
  margin-top: 50px;
  // margin-left: 20px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  .tab {
    &:hover {
      cursor: pointer;
    }
    margin-right: 20px;
  }
  .tab_active {
    margin-right: 20px;
    border-bottom: 2px solid var(--color-yellow);
    &:hover {
      cursor: pointer;
    }
  }
}

.scrollTable {
  margin-bottom: 30px;
}
